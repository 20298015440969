<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目利润趋势图</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'ProfitHistogram'"
            :yearIsShow="true"
            :list="dictData.yearList"
            v-model="sectionInfo.projectProfitYear"
            @change="onProfitHistogramChange"
            @changeData="onProfitHistogramDataChange"
        /></span>
      </div>
      <ProfitHistogram :projectProfitList="projectProfitList" />
      <el-table height="240px" border :data="projectProfitData">
        <el-table-column
          prop="contractAmountName"
          label="金额类型"
          align="center"
          fixed="left"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="januaryAmount" min-width="120" label="一月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.januaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="februaryAmount" min-width="120" label="二月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.februaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="marchAmount" min-width="120" label="三月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.marchAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="aprilAmount" min-width="120" label="四月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.aprilAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="mayAmount" min-width="120" label="五月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.mayAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="juneAmount" min-width="120" label="六月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.juneAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="julyAmount" min-width="120" label="七月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.julyAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="augustAmount" min-width="120" label="八月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.augustAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="septemberAmount" min-width="120" label="九月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.septemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="octoberAmount" min-width="120" label="十月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.octoberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="novemberAmount" min-width="120" label="十一月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.novemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="decemberAmount" min-width="120" label="十二月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.decemberAmount | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    ProfitHistogram: () => import('./profitHistogram.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
      },
      projectProfitList: [], // 项目利润趋势图
      projectProfitData: [],
      sectionInfo: {
        // 条件筛选
        // 年份
        projectProfitYear: null,
      },
      titleYear: '统计时间：',
    }
  },
  provide() {
    return {}
  },
  computed: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onProfitHistogramChange(arr) {
      this.projectProfitList = arr
    },
    onProfitHistogramDataChange(arr) {
      this.projectProfitData = arr
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.loading = true
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.projectProfitYear = dictVal

          const financiaProjectProfitTrend = await this.$api.statistics.financiaProjectProfitTrend(
            `date=${dictVal}`
          )
          const { projectExpenseResponseVo = {} } = financiaProjectProfitTrend.data
          const nameMapping = {
            actualReceiptsList: '收入',
            actualExpenditureList: '成本',
            fundBalanceList: '利润',
          }
          this.projectProfitList = Object.keys(projectExpenseResponseVo)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map((key, index) => ({
              name: nameMapping[key], // 根据键获取名称
              data: projectExpenseResponseVo[key],
            }))
          this.projectProfitData = projectExpenseResponseVo.contractPaymentListVoList || []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
</style>
